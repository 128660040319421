import { format } from 'date-fns/esm';
import DateFormats from './DateFormats';

export default class Base {
  getDateFromSQL(value: string, dateFormat: string) {
    if (!value || value === '0000-00-00 00:00:00') {
      return null;
    }
    const _format = dateFormat || DateFormats.DATE_FULL;
    return format(new Date(value.replace(/-/g, '/')), _format);
  }
}
