/* eslint-disable @typescript-eslint/naming-convention */
const DATE_FULL = 'MMMM dd, yyyy';
const DATE_TIME = 'MMMM dd, yyyy HH:mm a';
const MONTH_DAY = 'dd';
const SHORT_MONTH = 'MMM';
const DAY_SHORT_MONTH = 'dd MMM';
const DAY_SHORT_MONTH_SHORT_YEAR = 'dd MMM yy';

export default {
  DATE_FULL,
  MONTH_DAY,
  SHORT_MONTH,
  DAY_SHORT_MONTH,
  DAY_SHORT_MONTH_SHORT_YEAR,
  DATE_TIME,
};
