import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    // loadChildren:() => import( () => import('./items/items.module').then(m => m.ItemsModule)
    loadChildren: () =>
      import('./pages/home/home.module').then((m: any) => m.HomePageModule),
  },
  {
    path: 'live-availability',
    loadChildren: () =>
      import('./pages/live-availability/live-availability.module').then(
        (m: any) => m.LiveAvailabilityPageModule
      ),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./pages/booking/booking.module').then(
        (m: any) => m.BookingPageModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./public/auth/auth.module').then((m: any) => m.AuthPageModule),
  },
  {
    path: 'statement-history',
    loadChildren: () =>
      import('./pages/statement-history/statement-history.module').then(
        (m: any) => m.StatementHistoryPageModule
      ),
  },
  {
    path: 'owner-bookings',
    loadChildren: () =>
      import('./pages/owner-bookings/owner-bookings.module').then(
        (m: any) => m.OwnerBookingsPageModule
      ),
  },
  {
    path: 'guest-bookings',
    loadChildren: () =>
      import('./pages/guest-bookings/guest-bookings.module').then(
        (m: any) => m.GuestBookingsPageModule
      ),
  },
  {
    path: 'maintenance-invoices',
    loadChildren: () =>
      import('./pages/maintenance-invoices/maintenance-invoices.module').then(
        (m: any) => m.MaintenanceInvoicesPageModule
      ),
  },
  {
    path: 'correspondence-history',
    loadChildren: () =>
      import(
        './pages/correspondence-history/correspondence-history.module'
      ).then((m: any) => m.CorrespondenceHistoryPageModule),
  },
  {
    path: 'approve-maintenance',
    loadChildren: () =>
      import('./pages/approve-maintenance/approve-maintenance.module').then(
        (m: any) => m.ApproveMaintenancePageModule
      ),
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./pages/messages/messages.module').then(
        (m: any) => m.MessagesPageModule
      ),
  },
  {
    path: 'report-maintenance',
    loadChildren: () =>
      import('./pages/report-maintenance/report-maintenance.module').then(
        (m: any) => m.ReportMaintenancePageModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
