import Base from './Base';

export default class Area extends Base {
  id: number;
  address: string;
  name: string;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.address = data.address;
    this.name = data.name;
  }

  static parseFromData(data: any): Area {
    return new Area({
      id: data.id,
      address: data.address,
      name: data.name,
    });
  }
}
