import { Account } from './Account';
import CleanSettings from './CleanSettings';

export class Owner {
  id: number;
  email: string;
  mobile: string;
  firstName: string;
  lastName: string;
  fullName: string;
  token: string;
  displayMaintenanceImages: boolean;
  displayMaintenanceInvoices: boolean;
  displayApproveMaintenance: boolean;
  displayPastBookings: boolean;
  displayGuestNames: boolean;
  displayHomSureAds: boolean;
  account?: Account;
  cleanSettings?: CleanSettings;
  bookingCheckinTime: string;
  bookingLeadTime: number;

  constructor(data: any) {
    this.id = data.id;
    this.email = data.email;
    this.mobile = data.mobile;
    this.lastName = data.lastName;
    this.fullName = data.fullName;
    this.account = data.account;
    this.token = data.token;
    this.cleanSettings = data.cleanSettings;
    this.displayMaintenanceImages = data.displayMaintenanceImages;
    this.displayMaintenanceInvoices = data.displayMaintenanceInvoices;
    this.displayApproveMaintenance = data.displayApproveMaintenance;
    this.displayPastBookings = data.displayPastBookings;
    this.displayGuestNames = data.displayGuestNames;
    this.displayHomSureAds = data.displayHomSureAds;
    this.bookingLeadTime = data.bookingLeadTime;
    this.bookingCheckinTime = data.bookingCheckinTime;
  }

  static parseFromData(data: any): Owner {
    const owner = data.owner;
    return new Owner({
      id: owner.id,
      email: owner.email,
      mobile: owner.ph_mobile,
      firstName: owner.first_name,
      lastName: owner.last_name,
      fullName: owner.full_name,
      account: owner.account,
      token: data.token,
      cleanSettings: new CleanSettings(owner.clean_settings),
      displayMaintenanceImages: !!owner.display_maintenance_images,
      displayMaintenanceInvoices: !!owner.display_maintenance_invoices,
      displayApproveMaintenance: !!owner.display_approve_maintenance,
      displayPastBookings: !!owner.display_past_bookings,
      displayHomSureAds: !!owner.display_homsure_ads,
      displayGuestNames: !!owner.display_guest_names,
      bookingLeadTime: owner.booking_lead_time,
      bookingCheckinTime: owner.booking_checkin_time,
    });
  }
}
