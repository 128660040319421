import Base from '../Base';
import DateFormats from '../DateFormats';
import CorrespondenceLogData from './CorrespondenceLogData';
import { CorrespondenceType } from './CorrespondenceType';
import { CorrespondenceStatus } from './CorrespondenceStatus';

export default class Correspondence extends Base {
  id: number;
  contactId: number;
  reservationId: number;
  jobId: string;
  logId: number;
  logData: string;
  logDate: string;
  logDescription: string;
  logTable: string;
  logType: string;
  status: string;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.contactId = data.contactId;
    this.reservationId = data.reservationId;
    this.jobId = data.jobId;
    this.logId = data.logId;
    this.logData = data.logData;
    this.logDate = data.logDate;
    this.logDescription = data.logDescription;
    this.logTable = data.logTable;
    this.logType = data.logType;
    this.status = data.status;
  }

  static parseFromData(data: any): Correspondence {
    return new Correspondence({
      id: data.id,
      contactId: data.contact_id,
      reservationId: data.reservation_id,
      jobId: data.job_id,
      logId: data.log_id,
      logData: CorrespondenceLogData.parseFromData(
        data.log_type,
        data.log_data
      ),
      logDate: data.log_date,
      logDescription: data.log_description,
      logTable: data.log_table,
      logType: data.log_type,
      status: data.status,
    });
  }

  getDateCreated(dateFormat: string): string {
    return this.getDateFromSQL(
      this.logDate,
      dateFormat || DateFormats.DATE_TIME
    );
  }

  isEmail(): boolean {
    return this.logType === CorrespondenceType.EMAIL;
  }

  isSms(): boolean {
    return this.logType === CorrespondenceType.SMS;
  }

  isSmsReply(): boolean {
    return this.logType === CorrespondenceType.SMS_REPLY;
  }

  getDisplayType(): string {
    switch (this.logType) {
      case CorrespondenceType.EMAIL:
        return 'Email';
      case CorrespondenceType.SMS:
        return 'SMS';
      case CorrespondenceType.SMS_REPLY:
        return 'SMS Reply';
      default:
        return 'Unknown';
    }
  }

  getDisplayStatus(): string {
    switch (this.status) {
      case CorrespondenceStatus.SUCCESS:
        return 'Success';
      case CorrespondenceStatus.ERROR:
        return 'Error';
      case CorrespondenceStatus.PENDING_VALID:
        return 'Pending Valid';
      default:
        return 'Unknown';
    }
  }
}
