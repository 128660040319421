import Base from '../Base';
import CorrespondenceEmailLog from './CorrespondenceEmailLog';
import CorrespondenceEmptyLog from './CorrespondenceEmptyLog';
import CorrespondenceLog from './CorrespondenceLog';
import CorrespondenceSmsLog from './CorrespondenceSmsLog';
import CorrespondenceSmsReplyLog from './CorrespondenceSmsReplyLog';
import { CorrespondenceType } from './CorrespondenceType';

export default class CorrespondenceLogData extends Base {
  constructor() {
    super();
  }

  static parseFromData(logType: string, data: any): CorrespondenceLog {
    if (!data) {
      return new CorrespondenceEmptyLog();
    }

    let log: any;
    try {
      log = JSON.parse(data);
    } catch (e) {
      return new CorrespondenceEmptyLog();
    }
    switch (logType) {
      case CorrespondenceType.SMS:
        return CorrespondenceSmsLog.parseFromData(log);

      case CorrespondenceType.SMS_REPLY:
        return CorrespondenceSmsReplyLog.parseFromData(log);

      case CorrespondenceType.EMAIL:
        return CorrespondenceEmailLog.parseFromData(log);

      default:
        return new CorrespondenceEmptyLog();
    }
  }
}
