import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import Correspondence from '../../models/correspondence/Correspondence';
import { CorrespondenceResponse } from '../../models/correspondence/CorrespondenceResponse';
import { BaseService } from '../base.service';

export const correspondenceHistoryCacheBuster$ = new Subject<void>();

export interface CorrespondenceData {
  data: any[];
}

@Injectable({
  providedIn: 'root',
})
export class CorrespondenceService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  @Cacheable({
    maxAge: 1000 * 60 * 15,
    cacheBusterObserver: correspondenceHistoryCacheBuster$,
  })
  fetchAll(
    limit: number = 100,
    skip: number = 0
  ): Observable<Correspondence[]> {
    return this.callGet(
      `owner/correspondence?limit=${limit}&skip=${skip}`
    ).pipe(
      map((response: CorrespondenceResponse) => {
        if (response && response.data) {
          return response.data.map(Correspondence.parseFromData);
        }
        return [];
      }),
      catchError((response: HttpErrorResponse) => {
        return this.getResponseMessage(
          response,
          'Unable to fetch correspondence history'
        );
      })
    );
  }

  @Cacheable({
    maxAge: 1000 * 60 * 15,
  })
  fetchById(id: number): Observable<Correspondence> {
    return this.callGet(`owner/correspondence/${id}`).pipe(
      map((response: CorrespondenceResponse) => {
        if (response && response.data) {
          return Correspondence.parseFromData(response.data);
        }
        return null;
      }),
      catchError((response: HttpErrorResponse) => {
        return this.getResponseMessage(
          response,
          'Unable to fetch correspondence details'
        );
      })
    );
  }
}
