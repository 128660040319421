import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import Correspondence from '../../models/correspondence/Correspondence';
import { BaseStore } from '../base.store';
import {
  correspondenceHistoryCacheBuster$,
  CorrespondenceService,
} from './correspondence.service';

@Injectable({ providedIn: 'root' })
export class CorrespondenceStore extends BaseStore {
  limit = 100;
  skip = 0;
  switchOffInfiniteLoad = false;

  private readonly _entities = new BehaviorSubject<Correspondence[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly entities$ = this._entities.asObservable();

  constructor(protected entityService: CorrespondenceService) {
    super();
  }

  get entities(): Correspondence[] {
    return this._entities.getValue();
  }

  set entities(val: Correspondence[]) {
    this._entities.next(val);
  }

  getById(entityId: number): Observable<Correspondence> {
    const record = of(
      this.entities.find((entity: Correspondence) => entity.id === entityId) ||
        null
    );
    return record || this.loadById(entityId);
  }

  loadById(entityId: number): Observable<Correspondence> {
    return this.entityService.fetchById(entityId);
  }

  loadDataIfEmpty(): Observable<Correspondence[]> {
    if (this.entities.length === 0) {
      return this.load();
    }
    return of(this.entities);
  }

  refresh(event: any): Observable<Correspondence[]> {
    correspondenceHistoryCacheBuster$.next();
    this.switchOffInfiniteLoad = false;
    return this.load(event, false, 0);
  }

  load(
    event: any = null,
    merge = true,
    skip: number = null
  ): Observable<Correspondence[]> {
    if (this.switchOffInfiniteLoad) {
      if (event) {
        event.target.complete();
      }
      return EMPTY;
    }

    // we need to maintain skip value, it cannot be reset when we refresh in case that request fails we need to keep the original
    if (skip === null) {
      skip = this.skip;
    }

    this.loading = true;
    this.error = null;

    return this.entityService.fetchAll(this.limit, skip).pipe(
      finalize(() => (this.loading = false)),
      tap({
        next: (entities: Correspondence[]) => {
          if (event) {
            event.target.complete();
          }
          if (Array.isArray(entities) && entities.length < this.limit) {
            this.switchOffInfiniteLoad = true;
          }

          if (merge) {
            this.entities.push(...entities);
          } else {
            this.entities = entities;
            this.skip = 0;
          }
          this.skip += entities.length;
        },
        error: (error: string) => {
          this.error = error;
        },
      })
    );
  }

  clear(): void {
    this.entities = [];
    this.switchOffInfiniteLoad = false;
    this.skip = 0;
  }
}
