import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import Area from '../../models/Area';
import { AreaResponse } from '../../models/AreaResponse';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class AreaService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  @Cacheable({
    maxAge: 1000 * 60 * 15,
  })
  fetchAll(): Observable<Area[]> {
    return this.callGet('owner/areas').pipe(
      map((response: AreaResponse) => {
        if (response && Array.isArray(response.data)) {
          return response.data.map(Area.parseFromData);
        }
        return [];
      }),
      catchError((response: HttpErrorResponse) => {
        return this.getResponseMessage(response, 'Unable to fetch areas');
      })
    );
  }
}
