import Base from '../Base';

export default class Recipient extends Base {
  name: string;
  email: string;

  constructor(email: string, name: string) {
    super();
    this.name = name;
    this.email = email;
  }
}
