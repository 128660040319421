import Base from './../Base';

export default class CorrespondenceLog extends Base {
  protected rawLog: string = null;

  constructor(data: any) {
    super();
    this.rawLog = data;
  }

  hasData(): boolean {
    return !!this.rawLog;
  }
}
