import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, empty, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserStore } from './user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected router: Router, protected userStore: UserStore) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modified = req;
    if (!req.headers.has('Skip-Auth')) {
      const reference = this.userStore.user
        ? this.userStore.user.account.reference
        : null;
      modified = req.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Account-Reference': reference,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: this.userStore.user ? this.userStore.user.token : '',
        },
      });
      return next.handle(modified).pipe(
        catchError((error: any) => {
          if (error.status === 401 && !error.url.includes('owner/login')) {
            this.userStore.logout();
            this.router.navigate(['auth/login']);
            return EMPTY;
          }
          return throwError(() => error);
        })
      );
    } else {
      const headers = req.headers.delete('Skip-Auth');
      modified = req.clone({ headers });
      return next.handle(modified);
    }
  }
}
