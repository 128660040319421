import CorrespondenceLog from './CorrespondenceLog';

export default class CorrespondenceSmsReplyLog extends CorrespondenceLog {
  constructor(data: any) {
    super(data);
  }

  static parseFromData(data: any): CorrespondenceSmsReplyLog {
    return new CorrespondenceSmsReplyLog(data);
  }
}
