import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Owner } from '../models/Owner';
import { AuthenticationService } from './authentication.service';
import { BaseStore } from './base.store';

@Injectable({ providedIn: 'root' })
export class UserStore extends BaseStore {
  private readonly _user = new BehaviorSubject<Owner>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly user$ = this._user.asObservable();

  constructor(protected authenticationService: AuthenticationService) {
    super();
  }

  get user(): Owner {
    return this._user.getValue();
  }

  set user(val: Owner) {
    this._user.next(val);
  }

  async init(): Promise<void> {
    if (this.user) {
      return;
    }

    const user = await this.authenticationService.getCachedUser();
    if (user) {
      this.user = Owner.parseFromData(user);
      const refresh$ = this.refreshSettings();
      await lastValueFrom(refresh$);
    }
  }

  login(data: any) {
    return this.authenticationService.login(data).pipe(
      mergeMap((user: Owner) => {
        this.user = user;
        return of(user);
      })
    );
  }

  refreshSettings() {
    return this.authenticationService.refreshUserSettings(this.user.token).pipe(
      mergeMap((user: Owner) => {
        this.user = user;
        return of(user);
      })
    );
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    this.user = null;
  }

  isAuthenticated(): boolean {
    return this.user !== null;
  }
}
