import { animate, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertController, NavController, Platform } from '@ionic/angular';
import * as Sentry from '@sentry/angular';
import { Scope } from '@sentry/types';
import { CacheService } from 'ionic-cache';
import get from 'lodash-es/get';
import {
  concat,
  fromEvent,
  interval,
  merge,
  filter,
  of,
  Subscription,
} from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Owner } from './models/Owner';
import { AreaStore } from './services/area/area.store';
import { CorrespondenceStore } from './services/correspondence/correspondence.store';
import { StorageService } from './services/storage.service';
import { UserStore } from './services/user.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.scss'],
  animations: [
    trigger('buttonState', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnDestroy, OnInit {
  public isOnline = false;
  public wasOffline = false;
  public appPages: any = [];
  public showMenu = false;
  public ready = false;
  public showHomSureAds = false;
  public version: string = window['hhRelease'];

  protected update$: Subscription;
  protected updateCheck$: Subscription;

  constructor(
    protected platform: Platform,
    protected router: Router,
    protected nav: NavController,
    protected alertController: AlertController,
    protected swUpdate: SwUpdate,
    protected appRef: ApplicationRef,
    protected cache: CacheService,
    protected userStore: UserStore,
    protected areaStore: AreaStore,
    protected correspondenceStore: CorrespondenceStore,
    protected storage: StorageService
  ) {}

  ngOnInit() {
    this.checkLocalStorage();
    this.cache.setDefaultTTL(60 * 60);

    this.handleOffline();
    this.initializeUser();

    if (environment.production) {
      this.setupUpdateCheck();
      this.setupUpdate();
    }
  }

  checkLocalStorage(): void {
    console.log('checkLocalStorage');
    try {
      // check if we can access local storage
      const ls = window.localStorage;
      console.log(ls);
    } catch (e) {
      console.log(e);
      alert('Cookies must be enabled in your browser to sign in!');
    }
  }

  setAppPages(): any {
    if (!this.userStore.user) {
      return [];
    }

    this.showHomSureAds = this.userStore.user.displayHomSureAds;

    this.appPages = [
      {
        title: 'Home',
        url: '/',
        icon: 'home',
        canView: true,
      },
      {
        title: 'Live Availability',
        url: '/live-availability',
        icon: 'calendar',
        canView: true,
      },
      {
        title: 'Your Bookings',
        url: '/owner-bookings',
        icon: 'person',
        canView: true,
      },
      {
        title: 'Guest Bookings',
        url: '/guest-bookings',
        icon: 'people',
        canView: true,
      },
      {
        title: 'Make a Booking',
        url: '/booking',
        icon: 'add',
        canView: true,
      },
      {
        title: 'Statement History',
        url: '/statement-history',
        icon: 'stats-chart',
        canView: true,
      },
      {
        title: 'Correspondence History',
        url: '/correspondence-history',
        icon: 'mail',
        canView: true,
      },
      {
        title: 'Invoices',
        url: '/maintenance-invoices',
        icon: 'document',
        canView:
          this.userStore.user && this.userStore.user.displayMaintenanceInvoices,
      },
      {
        title: 'Approve Maintenance',
        url: '/approve-maintenance',
        icon: 'hammer',
        canView:
          this.userStore.user && this.userStore.user.displayApproveMaintenance,
      },
      {
        title: 'Send Message',
        url: '/messages',
        icon: 'mail',
        canView: true,
      },
      {
        title: 'Report Maintenance',
        url: '/report-maintenance',
        icon: 'clipboard',
        canView: true,
      },
    ];
  }

  handleOffline(): void {
    merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      of(navigator.onLine)
    ).subscribe((isOnline: boolean) => {
      this.isOnline = isOnline;
      if (isOnline && this.wasOffline) {
        merge(this.areaStore.loadDataIfEmpty()).subscribe();
      } else {
        this.wasOffline = true;
      }
    });
  }

  initializeUser() {
    this.userStore.user$.subscribe((owner: Owner) => {
      if (owner) {
        this.setAppPages();
        const userId = get(owner, 'id', null);
        const username = get(owner, 'fullName', null);
        const email = get(owner, 'email', null);
        const company = get(owner, 'account.company_name', null);
        const reference = get(owner, 'account.reference', null);
        Sentry.configureScope((scope: Scope) => {
          // @ts-ignore
          scope.setExtras(owner);
          scope.setTag('company_name', company);
          scope.setTag('reference', reference);
          scope.setUser({
            id: userId,
            email,
            username,
          });
        });
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
      this.ready = true;
    });
  }

  async logout(): Promise<any> {
    await this.userStore.logout();
    await this.storage.clear();
    this.areaStore.clear();
    this.correspondenceStore.clear();
    this.nav.setDirection('root');
    this.router.navigate(['auth/login']);
  }

  async refresh(): Promise<void> {
    const confirm = await this.alertController.create({
      header: 'Update',
      message: 'Do you want to reload the app?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {},
        },
        {
          text: 'Reload',
          handler: () => {
            this.swUpdate
              .activateUpdate()
              .then(() => {
                window.location.reload();
              })
              .catch(console.error);
          },
        },
      ],
    });
    await confirm.present();
  }

  setupUpdateCheck() {
    if (this.updateCheck$) {
      return;
    }
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable: boolean) => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    if (this.swUpdate.isEnabled) {
      this.updateCheck$ = everySixHoursOnceAppIsStable$.subscribe(() =>
        this.swUpdate.checkForUpdate()
      );
    }
  }

  setupUpdate() {
    if (this.update$) {
      return;
    }

    this.update$ = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe((evt: any) => {
        console.log(evt);
        this.alertController
          .create({
            header: 'Update',
            message:
              'New Update available! Reload the webapp to see the latest juicy changes.',
            buttons: [
              {
                text: 'Cancel',
                handler: () => {},
              },
              {
                text: 'Reload',
                handler: () => {
                  this.swUpdate
                    .activateUpdate()
                    .then(() => window.location.reload());
                },
              },
            ],
          })
          .then((confirm: HTMLIonAlertElement) => confirm.present())
          .catch(console.error);
      });
  }

  ngOnDestroy(): void {
    if (environment.production) {
      this.update$.unsubscribe();
      this.updateCheck$.unsubscribe();
    }
  }
}
