import CorrespondenceLog from './CorrespondenceLog';

export default class CorrespondenceSmsLog extends CorrespondenceLog {
  mobile: string;
  message: string;

  constructor(data: any) {
    super(data);
    this.mobile = data.mobile;
    this.message = data.message;
  }

  static parseFromData(data: any): CorrespondenceSmsLog {
    let mobile = null;
    let message = null;
    if (data.payload) {
      message = data.payload.sms_message;
    } else {
      mobile = data.mobile;
      message = data.message;
    }
    return new CorrespondenceSmsLog({
      mobile,
      message,
    });
  }
}
