import CorrespondenceLog from './CorrespondenceLog';
import Recipient from './Recipient';

export default class CorrespondenceEmailLog extends CorrespondenceLog {
  to: Recipient[];
  subject: string;
  title: string;
  body: string;

  constructor(data: any) {
    super(data);
    this.to = data.to;
    this.subject = data.subject;
    this.body = data.body;
  }

  static parseFromData(data: any): CorrespondenceEmailLog {
    const recipients: Recipient[] = [];

    if (recipients && Array.isArray(recipients) && recipients.length) {
      try {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data.to.map((recipient: { 0: string; 1: string }) => {
          const email = recipient[0];
          const name = recipient[1];
          if (email && name && email.length && name.length) {
            recipients.push(new Recipient(email, name));
          }
        });
      } catch (e) {}
    }

    return new CorrespondenceEmailLog({
      to: recipients,
      subject: data.subject,
      title: data.title,
      body: data.body_html,
    });
  }
}
