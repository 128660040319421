import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import Area from '../../models/Area';
import { BaseStore } from '../base.store';
import { AreaService } from './area.service';

@Injectable({ providedIn: 'root' })
export class AreaStore extends BaseStore {
  private readonly _areas = new BehaviorSubject<Area[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly areas$ = this._areas.asObservable();

  constructor(protected areaService: AreaService) {
    super();
  }

  get areas(): Area[] {
    return this._areas.getValue();
  }

  set areas(val: Area[]) {
    this._areas.next(val);
  }

  getAreaById(areaId: number): Observable<Area> {
    return of(this.areas.find((area: Area) => area.id === areaId) || null);
  }

  loadDataIfEmpty(): Observable<Area[]> {
    if (this.areas.length === 0) {
      return this.loadData().pipe(catchError(() => []));
    }
    return of(this.areas);
  }

  loadData(): Observable<Area[]> {
    this.loading = true;
    this.error = null;

    return this.areaService.fetchAll().pipe(
      finalize(() => (this.loading = false)),
      tap({
        next: (areas: Area[]) => {
          this.areas = areas;
        },
        error: (error: string) => {
          this.error = error;
        },
      })
    );
  }

  clear(): void {
    this.areas = [];
  }
}
