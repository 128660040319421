import CorrespondenceLog from './CorrespondenceLog';

export default class CorrespondenceEmptyLog extends CorrespondenceLog {
  constructor() {
    super(null);
  }

  static parseFromData(data: any): CorrespondenceEmptyLog {
    return new CorrespondenceEmptyLog();
  }
}
